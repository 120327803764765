import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import {
  object, string, array, number,
} from 'yup';
import { Formik, FieldArray, Form } from 'formik';
import http from '../../services/http.service';
import { currenciesService } from '../../services/currencies.service';
import Layout from '../../components/Layout/Layout';
import { emailValidationSchema } from '../../constans/validation-schemas';
import {
  WEBSITE_LINK_REQUIRED,
  WEBSITE_LINK_INVALID,
  HOW_FIND_REQUIRED,
  ADDRESS_INVALID,
  NUMBER_OF_ROOMS_REQUIRED,
  EXPRESS_PROPERTY_PRICE_REQUIRED,
  CURRENCY_REQUIRED,
  CHANNEL_MANAGER_REQUIRED,
} from '../../constans/error-messages';
import { REGEXP_URL } from '../../constans/validation';
import InputWrapper from '../../components/UI/InputWrapper/InputWrapper';
import CustomCreatableSelect from '../../components/UI/CustomCreatableSelect/CustomCreatableSelect';
import Label from '../../components/UI/Label/Label';
import ExpressProperty from '../../components/ExpressProperty/ExpressProperty';
import Button from '../../components/UI/Button/Button';
import { Input } from '../../components/UI/Input/Input';
import PlusIcon from '../../assets/img/icons/plus.svg';
import { EXPRESS_PROPERTY } from '../../constans/form-fields';
import { API } from '../../constans/http';
import { KORALGO_FOR_SELLER_LINK } from '../../constans/links';
import { useDisplayServerError } from '../../hooks/useDisplayServerError';
import classes from './index.module.scss';
import Modal from '../../components/UI/Modal/Modal';
import { BODY_BACKDROPED } from '../../constans/css-classes';

const propertyInitialValues = {
  link: EXPRESS_PROPERTY.weblink.initialValue,
  address: '',
  roomsCount: EXPRESS_PROPERTY.roomsCount.initialValue,
  currency: EXPRESS_PROPERTY.currency.initialValue,
  price: EXPRESS_PROPERTY.price.initialValue,
};

const initialValues = {
  howFindUs: EXPRESS_PROPERTY.howFindUs.initialValue,
  email: EXPRESS_PROPERTY.email.initialValue,
  channelManagersName: EXPRESS_PROPERTY.channelManager.initialValue,
  properties: [propertyInitialValues],
};

const propertyValidationSchema = object().shape({
  link: string()
    .required(WEBSITE_LINK_REQUIRED)
    .matches(REGEXP_URL, WEBSITE_LINK_INVALID),
  address: string().required(ADDRESS_INVALID),
  roomsCount: number()
    .required(NUMBER_OF_ROOMS_REQUIRED)
    .positive(NUMBER_OF_ROOMS_REQUIRED),
  currency: string().required(CURRENCY_REQUIRED),
  price: number()
    .required(EXPRESS_PROPERTY_PRICE_REQUIRED)
    // .moreThan(0, EXPRESS_PROPERTY_PRICE_REQUIRED),
    .positive(EXPRESS_PROPERTY_PRICE_REQUIRED),
});

const validationSchema = object().shape({
  properties: array().of(propertyValidationSchema),
  howFindUs: string().required(HOW_FIND_REQUIRED),
  channelManagersName: string().required(CHANNEL_MANAGER_REQUIRED),
});

const mainValidationSchema = validationSchema.concat(emailValidationSchema);

const Index = () => {
  const [serverError, setServerError] = useDisplayServerError();
  const [currencyArr, setCurrencyArr] = useState([]);
  const [formData, setFormData] = useState(null);
  const [successModalIsDisplayed, setSuccessModalIsDisplayed] = useState(false);
  const toggleSubmitModal = () => {
    document.body.classList.toggle(BODY_BACKDROPED);
    setSuccessModalIsDisplayed((prevState) => !prevState);
  };

  useEffect(() => {
    currenciesService().then((res) => {
      setCurrencyArr(res);
    });
  }, []);

  const formSubmitHandler = (values) => {
    setFormData(values);
    toggleSubmitModal();
  };

  const sendFormData = () => {
    http
      .post(API.SUPPLIERS, formData)
      .then(() => {
        navigate(KORALGO_FOR_SELLER_LINK);
      })
      .catch(({ message }) => {
        toggleSubmitModal();
        setServerError(message);
      });
  };

  return (
    <Layout mainClasses="flex-grow-1 flex">
      <div className={`${classes.wrapper} container`}>
        <div className={classes.container}>
          <div className={classes.title}>Expression of Interest</div>
          {serverError && <div className="titleError">{serverError}</div>}
          <Formik
            enableReinitialize
            onSubmit={formSubmitHandler}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={mainValidationSchema}
            initialValues={initialValues}
          >
            {({ values, dirty, errors }) => (
              <Form>
                <Modal
                  showClose={false}
                  modalClasses={classes.modal}
                  show={successModalIsDisplayed}
                  displayToggle={toggleSubmitModal}
                >
                  <div className={`${classes.modalTitle} h4-title`}>
                    Sending to Koralgo Admin
                  </div>
                  <div className={`${classes.modalSubtitle} t-500`}>
                    The Expression of Interest will be sent to Koralgo Admin
                  </div>
                  <Button
                    onClick={sendFormData}
                    classes="btn btn-uppercased btn_block btn_common t-600"
                  >
                    Send
                  </Button>
                </Modal>
                <div className={`${classes.supplierMainInfo} row`}>
                  <InputWrapper containerClasses="col-md-12 col-xl-6">
                    <Label>How did you hear about us?</Label>
                    <CustomCreatableSelect
                      options={EXPRESS_PROPERTY.howFindUs.values}
                      placeholder={EXPRESS_PROPERTY.howFindUs.placeholder}
                      name={EXPRESS_PROPERTY.howFindUs.name}
                    />
                  </InputWrapper>
                  <InputWrapper containerClasses="col-md-12 col-xl-6">
                    <Label>Email</Label>
                    <Input
                      type="email"
                      name={EXPRESS_PROPERTY.email.name}
                      placeholder={EXPRESS_PROPERTY.email.placeHolder}
                    />
                  </InputWrapper>
                  <InputWrapper containerClasses="col-md-12 col-xl-6">
                    <Label>What is the name of your channel manager?</Label>
                    <CustomCreatableSelect
                      options={EXPRESS_PROPERTY.channelManager.values}
                      placeholder={EXPRESS_PROPERTY.channelManager.placeholder}
                      name={EXPRESS_PROPERTY.channelManager.name}
                    />
                  </InputWrapper>
                </div>
                <FieldArray
                  name="properties"
                  render={(arrayHelpers) => (
                    <>
                      <ExpressProperty
                        arrayHelpers={arrayHelpers}
                        currencyArr={currencyArr}
                        errors={errors}
                        values={values}
                      />
                      <div
                        className={`${classes.formFooter} flex flex-between`}
                      >
                        <Button
                          disabled={values.properties.length >= 3}
                          type="button"
                          classes={`${classes.addBtn} btn btn-uppercased t-600`}
                          onClick={() => arrayHelpers.push(propertyInitialValues)}
                        >
                          <PlusIcon className={classes.icon} />
                          add another property
                        </Button>
                        <Button
                          disabled={!dirty}
                          type="submit"
                          classes={`${classes.submitBtn} btn btn-uppercased btn_block btn_common t-600`}
                        >
                          <span className="btn__text">Submit interest</span>
                        </Button>
                      </div>
                    </>
                  )}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default Index;
