import React from 'react';
import classes from '../../pages/express-interest/index.module.scss';
import InputWrapper from '../UI/InputWrapper/InputWrapper';
import Label from '../UI/Label/Label';
import { Input } from '../UI/Input/Input';
import { EXPRESS_PROPERTY } from '../../constans/form-fields';
import InputDropDowned from '../UI/InputDropDowned/InputDropDowned';
import CustomSelect from '../UI/CustomSelect/CustomSelect';
import RemoveModal from '../UI/RemoveModal/RemoveModal';

const ExpressProperty = ({
  values, errors, currencyArr, arrayHelpers,
}) => {
  const displayValueFromDropDownInputs = (currency, price) => `${currency.trim()} ${price.trim()}`;

  return values.properties.map((property, index) => (
    <div className={classes.propertyWrapper} key={`ser${index}`}>
      <div className={`${classes.propertyTitle} t-700`}>
        {`Property #${index + 1} information`}
        <RemoveModal
          showRemove={values.properties.length > 1}
          arrayHelpers={arrayHelpers}
          index={index}
          values={values.properties}
          title={`Property #${index + 1} information`}
          modalTitle={`Delete Property #${index + 1} Information`}
          modalSubtitle={`
          Are you sure you want to delete this property information? All fields
          will be cleared.`}
          modalSubmit={() => {
            arrayHelpers.remove(index);
          }}
        />
      </div>
      <div className="row">
        <InputWrapper containerClasses="col-md-12 col-xl-6">
          <Label>Website</Label>
          <Input
            type="text"
            name={`properties.${index}.${EXPRESS_PROPERTY.weblink.name}`}
            placeholder={EXPRESS_PROPERTY.weblink.placeHolder}
          />
        </InputWrapper>
        <InputWrapper containerClasses="col-md-12 col-xl-6">
          <Label>Address</Label>
          <Input
            type="text"
            name={`properties.${index}.address`}
            placeholder="Enter address here"
          />
        </InputWrapper>
        <InputWrapper containerClasses="col-md-12 col-xl-6">
          <Label>Number of Rooms</Label>
          <Input
            type="number"
            name={`properties.${index}.${EXPRESS_PROPERTY.roomsCount.name}`}
            placeholder={EXPRESS_PROPERTY.roomsCount.placeHolder}
          />
        </InputWrapper>
        <InputWrapper containerClasses="col-md-12 col-xl-6">
          <Label>Average Realized Rate per Room</Label>
          <InputDropDowned
            id="exprInt"
            errored={
              errors?.properties?.[index]?.currency
              || errors?.properties?.[index]?.price
            }
            placeHolder="Enter average realized rate"
            inputValues={displayValueFromDropDownInputs(
              `${values.properties[index].currency}`,
              `${values.properties[index].price}`,
            )}
          >
            <div className={`${classes.dropDownInputContainer} row`}>
              <InputWrapper containerClasses="col-md-12 col-xl-6">
                <Label>Currency</Label>
                <CustomSelect
                  name={`properties.${index}.${EXPRESS_PROPERTY.currency.name}`}
                  placeholder={EXPRESS_PROPERTY.currency.placeholder}
                  options={currencyArr}
                />
              </InputWrapper>
              <InputWrapper containerClasses="col-md-12 col-xl-6">
                <Label>Price</Label>
                <Input
                  inputWrapperStyles={classes.priceInput}
                  type="number"
                  name={`properties.${index}.${EXPRESS_PROPERTY.price.name}`}
                  placeholder={EXPRESS_PROPERTY.price.placeHolder}
                />
              </InputWrapper>
              <div className={`${classes.dropDownInputInfo} col-12 t-500`}>
                *Please provide the rough average realized nightly rate per room
                of this property over the last 12 months. This information is
                required to establish the pricing tier of the property.
              </div>
            </div>
          </InputDropDowned>
        </InputWrapper>
      </div>
    </div>
  ));
};

export default ExpressProperty;
